<template>
  <div v-if="!isFetching">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <md-card>
          <md-card-header
            class="md-card-header-icon"
            :class="getClass(headerColor)"
          >
            <div class="card-icon">
              <md-icon>perm_identity</md-icon>
            </div>
            <h4 class="title">
              Alterar o usuário escolhido
            </h4>
            <div style="display:inline-block;">
              <md-button
                @click="goToChangePassword"
                class="md-raised md-success mt-4"
              >
                alterar senha
              </md-button>
            </div>
          </md-card-header>

          <md-card-content>
            <div class="md-layout">
              <!-- <div class="md-layout-item md-small-size-100 md-size-33">
                <ValidationProvider
                  name="form.nomeEntidade"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label for="entidadeSelect">Selecionar Entidade</label>
                    <md-select
                      v-if="!isFetching3"
                      v-model="entidadeSelect"
                      name="entidadeSelect"
                      id="entidadeSelect"
                      @md-selected="selectedEntidade"
                    >
                      <md-option
                        v-for="item in entidadeSelectOption"
                        :key="item.idEntidade"
                        :label="item.idEntidade"
                        :value="item.idEntidade"
                      >
                        {{ item.nomeEntidade }}
                      </md-option>
                    </md-select>
                  </md-field>
                  <span
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div> -->
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <br />
                <ValidationProvider
                  name="form.nomeusuario"
                  :rules="{
                    regex: /^[a-zA-Z0-9\.\-\_\@]*$/,
                    min: 8,
                    max: 50,
                    required: true
                  }"
                  v-slot="{ passed, failed, errors }"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>Nome de Usuário</label>
                    <md-input v-model="form.nomeusuario" type="text"></md-input>
                  </md-field>
                  <span
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <br />
                <ValidationProvider
                  name="form.email"
                  :rules="{
                    // eslint-disable-next-line prettier/prettier
                  email: true,
                    required: true
                  }"
                  v-slot="{ passed, failed, errors }"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>Email</label>
                    <md-input v-model="form.email" type="email"></md-input>
                  </md-field>
                  <span
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider
                  name="form.nomeCompleto"
                  :rules="{
                    regex: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1\']*$/,
                    min: 3,
                    max: 250,
                    required: true
                  }"
                  v-slot="{ passed, failed, errors }"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>Nome Completo</label>
                    <md-input
                      name="Nome Completo"
                      v-model="form.nomeCompleto"
                      type="text"
                      :maxlength="250"
                      class="uppercase"
                    ></md-input>
                    <!--<p id="error">{{ errors[0] }}</p>-->
                  </md-field>
                  <span
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider
                  name="form.cpfUsuario"
                  :rules="{
                    regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                    min: 14,
                    max: 14,
                    required: true
                  }"
                  v-slot="{ passed, failed, errors }"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>CPF</label>
                    <md-input
                      v-model="form.cpfUsuario"
                      type="text"
                      :maxlength="14"
                    ></md-input>
                  </md-field>
                  <span
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 text-right">
                <md-button type="submit" class="md-raised md-success mt-4">
                  ATUALIZAR
                </md-button>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <md-card>
                  <md-card-header
                    class="md-card-header-icon md-card-header-green"
                  >
                    <div class="card-icon">
                      <md-icon>assignment</md-icon>
                    </div>
                    <h4 class="title">
                      Entidades, Papeis, Servidor e Pensionistas Relacionados
                    </h4>
                  </md-card-header>
                  <md-card-content>
                    <md-table
                      v-model="tableUsuarioEntidades"
                      class="table-striped"
                    >
                      <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <!--<md-table-cell md-label="Id">{{
                          item.idUsuarioEntidade
                        }}</md-table-cell>-->
                        <md-table-cell md-label="IdEntidade">{{
                          item.idEntidade
                        }}</md-table-cell>
                        <md-table-cell md-label="Nome Entidade">{{
                          item.nomeEntidade
                        }}</md-table-cell>
                        <md-table-cell md-label="Papel">{{
                          item.papel
                        }}</md-table-cell>
                        <md-table-cell md-label="Nome Completo">{{
                          item.nomeCompleto && item.nomeCompleto !== null
                            ? item.nomeCompleto
                            : "N/A"
                        }}</md-table-cell>
                        <md-table-cell md-label="CPF">{{
                          item.cpf && item.cpf !== null ? item.cpf : "N/A"
                        }}</md-table-cell>
                        <md-table-cell
                          md-label="Actions"
                          :class="getAlignClasses(item)"
                        >
                          <!--<md-button class="md-just-icon md-success md-round"
                            ><md-icon>edit</md-icon></md-button
                          >-->
                          <md-button
                            class="md-just-icon md-danger md-round"
                            @click="handleDelete(item)"
                            ><md-icon>close</md-icon></md-button
                          >
                        </md-table-cell>
                      </md-table-row>
                    </md-table>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-size-15">
                <md-button @click="showModal" class="md-raised md-success">
                  ADICIONAR
                  <md-icon>add</md-icon>
                </md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </ValidationObserver>
    <template>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">Adicionar Entidade <br /></h4>
          <small class="modal-title">
            - Apenas para Servidor e Pensionista<br />
          </small>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>
        <template slot="body">
          <ValidationObserver ref="observer2" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(adicionar)">
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.nomeEntidade"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label for="entidadeSelectModal"
                        >Selecionar Entidade</label
                      >
                      <md-select
                        v-if="!isFetching3"
                        v-model="entidadeSelectModal"
                        name="entidadeSelectModal"
                        id="entidadeSelectModal"
                        @md-selected="selectedEntidadeModal"
                        @md-opened="zIndexElevatorCall()"
                      >
                        <md-option
                          v-for="item in entidadeSelectOptionModal"
                          :key="item.idEntidade"
                          :label="item.idEntidade"
                          :value="item.idEntidade"
                        >
                          {{ item.nomeEntidade }}
                        </md-option>
                      </md-select>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.nomePapel"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label for="papelSelectModal">Selecionar Papel</label>
                      <md-select
                        id="selectOptionFieldModal"
                        v-if="!isFetching2"
                        v-model="papelSelectModal"
                        name="papelSelectModal"
                        @md-selected="selectPapelModal"
                        @click="showContactListModal = true"
                        @md-opened="zIndexElevatorCall()"
                      >
                        <md-option
                          v-for="item in papelSelectOptionModal"
                          :key="item.idPapel"
                          :label="item.nomePapel"
                          :value="item.idPapel"
                        >
                          {{ item.nomePapel }}
                        </md-option>
                      </md-select>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                  <ValidationProvider
                    v-show="showContactListModal"
                    name="form.idServidorOrPensionista"
                    :rules="{
                      regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
                      min: 36,
                      required: isRequiredIdServidorOrPensionista
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Selecione um Servidor ou Pensionista</label>
                      <md-input
                        ref="idServidorOrPensionistaRef"
                        v-model="servidorOrPensionistaSelectedState.id"
                        type="text"
                        autocomplete="off"
                      >
                      </md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >Selecione um Servidor ou Pensionista Abaixo
                    </span>
                  </ValidationProvider>
                  <list-contacts
                    :key="componentKeyToRerender"
                    v-show="showContactListModal"
                    :searchText="search"
                    :items_prop="tableData"
                    :nomePapelSelecionado="nomePapelSelecionadoModal"
                  ></list-contacts>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-100 text-left">
                  <md-button type="submit" class="md-raised md-success mt-4">
                    ADICIONAR
                  </md-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
        <template slot="footer"> </template>
      </modal>
    </template>
  </div>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import zIndexElevator from "../js/zIndexElevator";
import ListContacts from "@/components/ListContacts";
//@click="zIndexElevatorCall()"
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { Modal } from "@/components";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex =
      "O campo Nome de Usuario só pode conter numeros, letras e caracteres especiais ' .   -   _   @ ' .";
  }
}
var fields = {
  "form.cpfUsuario": {
    regex: "O campo CPF é inválido !"
  },
  "form.nomeCompleto": {
    regex: "Nome Completo deve ter apenas caracteres e acentos e espaços."
  },
  "form.idServidorOrPensionista": {
    regex: "id com formato inválido."
  }
};
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
Vue.set(pt_br, "fields", fields);
localize("pt_BR", pt_br);

export default {
  props: {
    headerColor: {
      type: String,
      default: "green"
    },
    id: {
      type: String
    }
  },
  components: { ListContacts, Modal },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapState("user", ["usuario"]),
    ...mapGetters("servidor_pensionista", [
      "getServidorOrPensionistaSelectedState"
    ]),
    servidorOrPensionistaSelected: getters =>
      getters.getServidorOrPensionistaSelectedState,
    servidorOrPensionistaSelectedState: {
      get() {
        return this.servidorOrPensionistaSelected;
      },
      set(newServidorOrPensionista) {
        return newServidorOrPensionista;
      }
    },
    user_profile_state: state => state.user_profile,
    usuarioState: state => state.usuario
  },
  data() {
    return {
      classicModal: false,
      isRequiredIdServidorOrPensionista: true,
      selectedUsuarioId: this.id,
      nomePapelSelecionado: "",
      componentKeyToRerender: 0,
      showContactList: false,
      showContactListModal: false,
      search: "",
      isFetching: true,
      isFetching2: true,
      isFetching3: true,
      mensagem: "",
      form: {
        idUsuario: null,
        nomeusuario: null,
        email: null,
        nomeCompleto: null,
        cpfUsuario: null,
        idServidorOrPensionista: null
        //papelAssociacao: null
      },
      form2: {
        idUsuario: null,
        idEntidade: null,
        idPapel: null,
        idServidorOrPensionista: null,
        papelAssociacao: null
      },
      papel: "",
      papelSelectOption: [],
      papelSelect: "",
      entidadeSelect: "",
      entidadeSelectOption: [],
      tableData: [],
      papelSelectModal: "",
      entidadeSelectModal: "",
      entidadeSelectOptionModal: [],
      papelSelectOptionModal: [],
      nomePapelSelecionadoModal: "",
      isRequiredIdServidorOrPensionistaModal: true,
      currentSort: "nomeCompleto",
      currentSortOrder: "desc",
      tableUsuarioEntidades: []
    };
  },
  beforeMount() {
    this.getPapel();
    this.getPapelAll();
    this.getEntidadeAll().then(() => {
      this.loadUserForm();
    });
    //this.getUserById();
  },
  mounted() {},
  methods: {
    ...mapActions("user", [
      "ActionPUTUser",
      "ActionSetUser",
      "ActionGetUserById",
      "ActionGetIsPensionistaOrServidor",
      "ActionDELETEUsuarioEntidade",
      "ActionPOSTUsuarioEntidade"
    ]),
    ...mapActions("papel", ["ActionGetPapelAll"]),
    ...mapActions("servidor_pensionista", [
      "ActionGetServidorOrPensionistaToAssingmentFromEntidadeAll",
      "ActionSetServidorOrPensionistaSelected"
    ]),
    ...mapActions("entidade", ["ActionGetEntidadeAll"]),
    getClass: function(headerColor) {
      return "md-card-header-" + headerColor + "";
    },
    zIndexElevatorCall: function() {
      zIndexElevator();
    },
    async reload() {
      this.getUserById(this.selectedUsuarioId, this.papel).then(res => {
        this.servidorOrPensionistaSelectedState = {
          nomeCompleto: "",
          cpf: "",
          siape: "",
          id: ""
        };
        if (
          // eslint-disable-next-line prettier/prettier
            typeof this.usuarioState !== 'undefined' &&
          this.usuarioState !== "" &&
          this.usuarioState !== null
        ) {
          this.tableUsuarioEntidades = this.usuarioState.usuarioEntidade;
        } else {
          this.tableUsuarioEntidades = res.usuarioEntidade;
        }
      });
    },
    async loadUserForm() {
      this.ActionSetServidorOrPensionistaSelected({});
      this.getUserById(this.selectedUsuarioId, this.papel).then(response => {
        //this.form.idEntidade = res.idEntidade;
        this.servidorOrPensionistaSelectedState = {
          nomeCompleto: "",
          cpf: "",
          siape: "",
          id: ""
        };
        if (
          // eslint-disable-next-line prettier/prettier
          typeof this.usuarioState !== 'undefined' &&
          this.usuarioState !== "" &&
          this.usuarioState !== null
        ) {
          this.form.nomeusuario = this.usuarioState.nomeusuario;
          this.form.email = this.usuarioState.email;
          this.form.cpfUsuario = this.usuarioState.cpfUsuario;
          this.form.nomeCompleto = this.usuarioState.nomeCompleto;
          this.form.idServidorOrPensionista = "";
          this.tableUsuarioEntidades = this.usuarioState.usuarioEntidade;
        } else {
          this.form.nomeusuario = response.nomeusuario;
          this.form.email = response.email;
          this.form.nomeCompleto = response.nomeCompleto;
          this.form.cpfUsuario = response.cpfUsuario;
          this.form.idServidorOrPensionista = "";
          this.tableUsuarioEntidades = response.usuarioEntidade;
        }
        if (this.entidadeSelectOption.length > 0) {
          this.entidadeSelect = this.form.idEntidade;
          let copiedObject = Object.assign({}, this.entidadeSelectOption);
          let key = Object.keys(copiedObject).find(k => {
            copiedObject[k].idEntidade === this.entidadeSelect;
          });
          delete copiedObject[key];
          this.entidadeSelectOptionModal = copiedObject;
        }
        if (this.papelSelectOption.length > 0) {
          let copiedObject = Object.assign({}, this.papelSelectOption);
          this.papelSelectOptionModal = copiedObject;

          /*let key1 = Object.keys(copiedObject).find(
            k => copiedObject[k].nomePapel === "ADMIN"
          );
          delete copiedObject[key1];
          let key2 = Object.keys(copiedObject).find(
            k => copiedObject[k].nomePapel === "ADMIN_ENTIDADE"
          );
          delete copiedObject[key2];
          */
        }
        /*this.getIsPensionistaOrServidor(
          this.selectedUsuarioId,
          this.papel
        ).then(res => {
          let idPapel = this.form.idPapel;
          let index = -1;
          let resultItem = this.papelSelectOption.find(function(item, i) {
            if (item.idPapel === idPapel) {
              index = i;
              return item;
            }
          });
          if (
            resultItem.nomePapel !== "ADMIN" &&
            resultItem.nomePapel !== "ADMIN_ENTIDADE"
          ) {
            if (resultItem.nomePapel === "PENSIONISTA") {
              this.warningIfPensionistaOrServidorNotHasAssociation(res);
              if (res.nomePapel && res.nomePapel === "PENSIONISTA") {
                this.form.idServidorOrPensionista = res.idPensionista;
                this.servidorOrPensionistaSelectedState.id = res.idPensionista;
                this.form.papelAssociacao = res.nomePapel;
              }
            } else if (resultItem.nomePapel === "SERVIDOR") {
              this.warningIfPensionistaOrServidorNotHasAssociation(res);
              if (res.nomePapel && res.nomePapel === "SERVIDOR") {
                this.form.idServidorOrPensionista = res.idServidor;
                this.servidorOrPensionistaSelectedState.id = res.idServidor;
                this.form.papelAssociacao = res.nomePapel;
              }
            }
          } else {
            this.isRequiredIdServidorOrPensionista = false;
            this.form.idServidorOrPensionista = "";
            this.servidorOrPensionistaSelectedState = { id: "" };
          }
        });*/
      });
    },
    warningIfPensionistaOrServidorNotHasAssociation(res) {
      if (res.mensagem) {
        this.mensagem = res.mensagem;
        Swal.fire({
          //title: "Sucesso",
          icon: "warning",
          text: this.mensagem,
          showCancelButton: false,
          customClass: {
            confirmButton: "md-button md-success btn-fill"
          },
          buttonsStyling: false,
          confirmButtonText: "Ok"
        });
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    async getPapelAll() {
      try {
        let papel = this.papel;
        let response = await this.ActionGetPapelAll({ papel }).then(
          response => {
            let papelFilter = response.body;
            papelFilter.sort((a, b) => {
              if (a.nomePapel < b.nomePapel) return -1;
              if (a.nomePapel > b.nomePapel) return 1;
              return 0;
            });
            if (this.papel === "ADMIN") {
              this.papelSelectOption = papelFilter;
            } else {
              let index = -1;
              let filteredResult = papelFilter.find(function(item, i) {
                if (item.nomePapel === "ADMIN") {
                  index = i;
                  return item;
                }
              });
              papelFilter.splice(index, 1);
              this.papelSelectOption = papelFilter;
            }
            this.isFetching2 = false;
          }
        );
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getEntidadeAll() {
      try {
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        await this.ActionGetEntidadeAll({ papel, idEntidade }).then(
          response => {
            let result = response.body;
            if (this.papel === "ADMIN") {
              result.sort((a, b) => {
                if (a.nomeEntidade < b.nomeEntidade) return -1;
                if (a.nomeEntidade > b.nomeEntidade) return 1;
                return 0;
              });
              this.entidadeSelectOption = result;
            } else {
              this.entidadeSelectOption = [];
              this.entidadeSelectOption.push({
                idEntidade: result.idEntidade,
                nomeEntidade: result.nomeEntidade
              });
              this.form.idEntidade = this.user_profile_state.idEntidade;
              this.entidadeSelect = this.user_profile_state.idEntidade;
              this.entidadeSelectOption = this.entidadeSelectOption;
            }
            this.isFetching = false;
            this.isFetching3 = false;
            return this.entidadeSelectOption;
          }
        );
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async submit() {
      try {
        let id = this.selectedUsuarioId;
        this.form.idUsuario = id;
        this.form.idServidorOrPensionista = this.servidorOrPensionistaSelectedState.id;
        let idPapel = this.form.idPapel;
        let index = -1;
        let resultItem = this.papelSelectOption.find(function(item, i) {
          if (item.idPapel === idPapel) {
            index = i;
            return item;
          }
        });
        //this.form.papelAssociacao = resultItem.nomePapel;
        let papel = this.papel;
        let form = this.form;
        let response = await this.ActionPUTUser({ id, papel, form });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não")) {
              Swal.fire({
                //title: "Sucesso",
                icon: "warning",
                text: this.mensagem,
                showCancelButton: false,
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false,
                confirmButtonText: "Ok"
              });
            } else {
              Swal.fire({
                //title: "Sucesso",
                icon: "success",
                text: this.mensagem,
                showCancelButton: false,
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false,
                confirmButtonText: "Ok"
              }).then(result => {
                if (result.value) {
                  this.ActionSetServidorOrPensionistaSelected({});
                  return window._Vue.$router
                    .push({ name: "Usuarios" })
                    .catch(err => {});
                }
              });
            }
          } else {
            Swal.fire({
              //title: "Sucesso",
              icon: "warning",
              text: "OPSSS!! Algo deu Errado.",
              showCancelButton: false,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false,
              confirmButtonText: "Ok"
            });
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async adicionar() {
      try {
        let id = this.selectedUsuarioId;
        this.form2.idUsuario = id;
        this.form2.idServidorOrPensionista = this.servidorOrPensionistaSelectedState.id;
        let idPapel = this.form2.idPapel;
        let index = -1;
        let resultItem = this.papelSelectOption.find(function(item, i) {
          if (item.idPapel === idPapel) {
            index = i;
            return item;
          }
        });
        this.form2.papelAssociacao = resultItem.nomePapel;
        let papel = this.papel;
        let form = this.form2;
        await this.ActionPOSTUsuarioEntidade({ id, papel, form }).then(
          response => {
            this.classicModalHide();
            if (response.status === 200) {
              if (response.body.mensagem) {
                this.mensagem = response.body.mensagem;
                if (this.mensagem.includes("não")) {
                  Swal.fire({
                    //title: "Sucesso",
                    icon: "warning",
                    text: this.mensagem,
                    showCancelButton: false,
                    customClass: {
                      confirmButton: "md-button md-success btn-fill"
                    },
                    buttonsStyling: false,
                    confirmButtonText: "Ok"
                  });
                } else {
                  Swal.fire({
                    //title: "Sucesso",
                    icon: "success",
                    text: this.mensagem,
                    showCancelButton: false,
                    customClass: {
                      confirmButton: "md-button md-success btn-fill"
                    },
                    buttonsStyling: false,
                    confirmButtonText: "Ok"
                  }).then(result => {
                    if (result.value) {
                      this.ActionSetServidorOrPensionistaSelected({});
                      this.reload();
                      this.showContactListModal = false;
                      this.checkboxAutoGerarSenha = false;

                      this.papelSelectModal = undefined;
                      this.entidadeSelectModal = undefined;
                    }
                  });
                }
              } else {
                Swal.fire({
                  //title: "Sucesso",
                  icon: "warning",
                  text: "OPSSS!! Algo deu Errado.",
                  showCancelButton: false,
                  customClass: {
                    confirmButton: "md-button md-success btn-fill"
                  },
                  buttonsStyling: false,
                  confirmButtonText: "Ok"
                });
              }
            }
          }
        );
      } catch (err) {
        this.classicModalHide();
        this.handleError(err);
      }
    },
    async getUserById(id, papel) {
      try {
        let idEntidade = this.user_profile_state.idEntidade;
        let resp = await this.ActionGetUserById({ id, papel, idEntidade }).then(
          response => {
            if (response.status === 200) {
              if (response.body.mensagem) {
                this.mensagem = response.body.mensagem;
                Swal.fire({
                  //title: "Sucesso",
                  icon: "warning",
                  text: this.mensagem,
                  showCancelButton: false,
                  customClass: {
                    confirmButton: "md-button md-success btn-fill"
                  },
                  buttonsStyling: false,
                  confirmButtonText: "Ok"
                });
              } else {
                return response.body;
              }
            }
          }
        );
        return resp;
      } catch (err) {
        this.handleError(err);
      }
    },
    async getIsPensionistaOrServidor(id, papel) {
      try {
        let response = await this.ActionGetIsPensionistaOrServidor({
          id,
          papel
        });
        if (response.status === 200) {
          if (response.body.mensagem) {
            return response.body;
          } else {
            return response.body;
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    goToChangePassword() {
      window._Vue.$router //.push({ path: `usuarios/editar_usuario/${id}` })
        .push({ name: "Change User Password", params: { id: this.id } })
        .catch(err => {});
    },
    classicModalHide: function() {
      this.classicModal = false;
      this.showContactListModal = false;
    },
    showModal() {
      this.tableData = [];
      this.entidadeSelectModal = undefined;
      this.classicModal = true;
      this.showContactList = false;
      this.servidorOrPensionistaSelectedState = {
        nomeCompleto: "",
        cpf: "",
        siape: "",
        id: ""
      };
      this.ActionSetServidorOrPensionistaSelected({});
      this.papelSelectModal = "";
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },

    forceRerender() {
      this.componentKeyToRerender += 1;
    },
    selectedEntidadeModal(item) {
      this.form2.idEntidade = item;
      if (
        // eslint-disable-next-line prettier/prettier
        typeof this.nomePapelSelecionadoModal !== 'undefined' &&
        this.nomePapelSelecionadoModal !== "" &&
        this.nomePapelSelecionadoModal !== null
      ) {
        this.selectPapelModal(this.form2.idPapel);
      }
    },
    /*selectedEntidade(item) {
      this.form.idEntidade = item;
      if (
        // eslint-disable-next-line prettier/prettier
        typeof this.nomePapelSelecionado !== 'undefined' &&
        this.nomePapelSelecionado !== "" &&
        this.nomePapelSelecionado !== null
      ) {
        this.selectPapel(this.form.idPapel);
      }
    },
    async selectPapel(item) {
      if (
        // eslint-disable-next-line prettier/prettier
        typeof item !== 'undefined' &&
        item !== "" &&
        item !== null
      ) {
        this.isRequiredIdServidorOrPensionista = true;
        this.form.idPapel = item;
        let id = item;
        let index = -1;
        let filteredResult = this.papelSelectOption.find(function(item, i) {
          if (item.idPapel === id) {
            index = i;
            return item;
          }
        });
        if (
          filteredResult.nomePapel === "PENSIONISTA" ||
          filteredResult.nomePapel === "SERVIDOR"
        ) {
          if (this.form.idEntidade != null) {
            let idEntidade = this.form.idEntidade;
            let nomePapel = filteredResult.nomePapel;
            this.getServidorOrPensionistaToAssingmentFromEntidade(
              idEntidade,
              nomePapel
            );
            await this.delay(2000);
            this.forceRerender();
            //this.showContactList = true;
          } else {
            this.form.idPapel = null;
            this.papelSelect = undefined;
            Swal.fire({
              icon: "warning",
              title: "Selecione uma Entidade antes!",
              text:
                "Uma entidade deve ser escolhida antes para filtra Servidores ou Pensionistas",
              customClass: {
                confirmButton: "md-button md-info btn-fill"
              },
              buttonsStyling: false
            });
          }
        } else {
          this.form.idServidorOrPensionista = undefined;
          this.ActionSetServidorOrPensionistaSelected({});
          this.isRequiredIdServidorOrPensionista = false;
          this.showContactList = false;
        }
      }
    },*/
    async selectPapelModal(item) {
      if (
        // eslint-disable-next-line prettier/prettier
        typeof item !== 'undefined' &&
        item !== "" &&
        item !== null
      ) {
        this.isRequiredIdServidorOrPensionista = true;
        this.form2.idPapel = item;
        let id = item;
        let index = -1;

        let key = Object.keys(this.papelSelectOptionModal).find(
          k => this.papelSelectOptionModal[k].idPapel === id
        );

        let filteredResult = this.papelSelectOptionModal[key];

        if (
          filteredResult.nomePapel === "PENSIONISTA" ||
          filteredResult.nomePapel === "SERVIDOR"
        ) {
          if (this.form2.idEntidade != null) {
            let idEntidade = this.form2.idEntidade;
            let nomePapel = filteredResult.nomePapel;
            this.getServidorOrPensionistaToAssingmentFromEntidade(
              idEntidade,
              nomePapel
            ).then(response => {
              this.forceRerender();
            });
            //this.showContactList = true;
          } else {
            this.form2.idPapel = null;
            this.papelSelectModal = undefined;
            Swal.fire({
              icon: "warning",
              title: "Selecione uma Entidade antes!",
              text:
                "Uma entidade deve ser escolhida antes para filtra Servidores ou Pensionistas",
              customClass: {
                confirmButton: "md-button md-info btn-fill"
              },
              buttonsStyling: false
            });
          }
        } else {
          this.form2.idServidorOrPensionista = undefined;
          this.ActionSetServidorOrPensionistaSelected({});
          this.isRequiredIdServidorOrPensionista = false;
          this.showContactListModal = false;
        }
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async getServidorOrPensionistaToAssingmentFromEntidade(
      idEntidade,
      nomePapel
    ) {
      try {
        let papel = this.papel;
        let body = { idEntidade: idEntidade, nomePapel: nomePapel };
        let response = await this.ActionGetServidorOrPensionistaToAssingmentFromEntidadeAll(
          { papel, body }
        );
        //console.log(response);
        if (response.status === 200) {
          let results = response.body;
          //console.log(results);
          if (!results.mensagem) {
            this.nomePapelSelecionadoModal = nomePapel;
            this.tableData = results;
          } else {
            if (results.mensagem.includes("não encontrados")) {
              this.nomePapelSelecionadoModal = nomePapel;
              this.tableData = [];
            }
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    handleDelete(item) {
      if (this.tableUsuarioEntidades.length <= 1) {
        Swal.fire({
          title: "Relacionamento não pode ser Excluido !",
          text: `O usuário deve estar relacionado ao menos uma entidade!`,
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "md-button md-success md-raised mt-4"
          },
          buttonsStyling: false
        });
      } else {
        Swal.fire({
          title: "Você tem certeza ?",
          text: `Se você excluir este relacionamento um Usuario perderá acesso ao sistema! Isto será irreversível !`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir isto!",
          cancelButtonText: "CANCELAR",
          customClass: {
            confirmButton: "md-button md-success md-raised mt-4",
            cancelButton: "md-button md-danger md-raised mt-4"
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.deleteRow(item);
          }
        });
      }
    },
    async deleteRow(item) {
      try {
        let id = item.idUsuarioEntidade;
        let papel = this.papel;
        await this.ActionDELETEUsuarioEntidade({ id, papel }).then(response => {
          if (response.status === 200) {
            let indexToDelete = this.tableUsuarioEntidades.findIndex(
              tableRow => tableRow.idUsuarioEntidade === item.idUsuarioEntidade
            );
            if (indexToDelete >= 0) {
              this.tableUsuarioEntidades.splice(indexToDelete, 1);
            }
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não")) {
              Swal.fire({
                title: `Não Excluido!`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "success",
                title: `Excluido!`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
            this.reload;
          }
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    getAlignClasses: ({ id }) => ({
      "text-right": id
    })
  },
  watch: {
    "form.cpfUsuario": function(val, oldVal) {
      let cpf = cpfMask(val);
      this.form.cpfUsuario = cpf;
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.switch-div {
  /*display: inline-block;*/
  float: right;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}

::v-deep .modal-body.text-center {
  height: 380px;
  overflow-y: auto;
}
</style>
