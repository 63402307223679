import $ from "jquery";

const zIndexElevator = function() {
  // Change this selector to find whatever your 'boxes' are
  //console.log("AQUIIIIII1");
  var boxes = $(
    "div.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default"
  );
  //console.log("AQUIIIIII2");
  // Set up click handlers for each box

  var el = boxes, // The box that was clicked
    max = 0;
  //console.log("AQUIIIIII3");
  el.css("z-index", 10000);
};

export default zIndexElevator;
